.navbar{
	flex-grow: 2;
	padding-left:64px;
	padding-right: 64px;
}
.navbar ul {
	display: flex;
	list-style: none;
	margin-left: auto;
	align-items: center;
	justify-content: space-around;
	margin:0 auto;
	padding:0;
}

.navbar ul li a{
	color: #eee;
	font-weight: normal;
	font-size: 16px;
}
.navbar ul li a:hover {
	border-bottom: 1px solid white;
}
.navbar a, .navbar a:visited, .navbar a:active{
	text-decoration: none;
}

.navbar ul li {
	border-bottom: 1px solid transparent;
}
.navbar ul li a:hover{
	color: white;
}
.navbar .extraItems {
	display: inline-block;
	align-items: center;
}
.extraContainer {
	max-width: 1024px;
	display: flex;
	list-style: none;
	margin-left: auto;
	align-items: center;
	
	margin:0 auto;
	padding:0;
}
.extraContainer a {
	color: white;
	display: inline-block;
	margin-right: 8px;
}
@media only screen and (max-width: 600px) {
    .navbar {
		display: none;
	}
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .navbar {
		display: block;
		flex-grow: none;
		margin: 8px 0 0 0;
		padding: 8px 32px 8px 32px;
		background-color: #69188c;

	}
	.navbar ul {
		display: block;
	}
	.navbar ul li{
		display: inline-block;
		margin-right: 16px;
	}
}