.mobileNavContainer {
	position: fixed;
	height: 100%;
	width: 100%;
	z-index:10001;
	background-color: #faf1fb;
	top: 56px;
	padding: 8px;
	color: #9C27B0;
}
.fab {
	height: 56px;
	width: 56px;
	border-radius: 64px;
	position: fixed;
	bottom: 0;
	right:0;
	margin: 16px;
	background: #9069ef;
	color: white;
	border: none;
	z-index: 10002;
	  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.navbar ul {
	padding: 8px;
}
.navbar ul li {
	display: inline-block;
	background: white;
	border-radius: 8px;
	padding: 8px;
	margin: 8px;
	
}
.navbar ul li a{
	text-decoration: none;
	color: blue;
}
.navbar ul li a:visited{
	text-decoration: none;
	color: blue;
}