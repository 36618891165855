.cartWrapper {
	font-size: 14px;
	cursor: pointer;
	font-weight: 600;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-right: 32px;
}
.cartWrapper .number {
	background: #fdfaff;
	height: 24px;
	width: 24px;
	border-radius: 48px;
	color:#7B1FA2;
	line-height: 24px;
	text-align: center;
	margin-left: 4px;

}
.cartWrapper:hover{
	text-decoration: underline;
}
